import React from "react";
import WorkingTogether from "../components/workingTogether/WorkingTogether";

const Contacts = () => {
  return (
    <div>
      <WorkingTogether />
    </div>
  );
};

export default Contacts;
