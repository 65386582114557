import React from 'react'
import './App.css'
import Layout from './components/layout/Layout'


const App = () => {
  return (
    <div>
      <Layout/>
    </div>
  )
}

export default App